"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.animationDuration = exports.render = exports.anim = void 0;
const util = require("./util");
const board_1 = require("./board");
function anim(mutation, state, fadeOnly = false, noCaptSequences = false) {
    return state.animation.enabled ? animate(mutation, state, fadeOnly, noCaptSequences) : render(mutation, state);
}
exports.anim = anim;
function render(mutation, state) {
    const result = mutation(state);
    state.dom.redraw();
    return result;
}
exports.render = render;
function animationDuration(state) {
    const anim = state.animation.current;
    if (!anim)
        return 0;
    let plan = anim.plan, total;
    const factor = (plan.nextPlan && !util.isObjectEmpty(plan.nextPlan.anims)) ? 2.2 : 1, duration = state.animation.duration / factor;
    total = duration;
    while (plan.nextPlan && !util.isObjectEmpty(plan.nextPlan.anims)) {
        plan = plan.nextPlan;
        total += duration;
    }
    return total;
}
exports.animationDuration = animationDuration;
function makePiece(key, boardSize, piece) {
    return {
        key: key,
        pos: util.key2pos(key, boardSize),
        piece: piece
    };
}
function closer(piece, pieces) {
    return pieces.sort((p1, p2) => {
        return util.distanceSq(piece.pos, p1.pos) - util.distanceSq(piece.pos, p2.pos);
    })[0];
}
function ghostPiece(piece) {
    if (piece.role === 'man')
        return { role: 'ghostman', color: piece.color, promoted: piece.promoted, kingMoves: piece.kingMoves };
    else if (piece.role === 'king')
        return { role: 'ghostking', color: piece.color, promoted: piece.promoted, kingMoves: piece.kingMoves };
    else
        return { role: piece.role, color: piece.color, promoted: piece.promoted, kingMoves: piece.kingMoves };
}
function isPromotablePos(color, pos, boardSize) {
    return (color === 'white' && pos[1] === 1) || (color === 'black' && pos[1] === boardSize[1]);
}
function computePlan(prevPieces, current, fadeOnly = false, noCaptSequences = false) {
    let missingsW = [], missingsB = [], newsW = [], newsB = [];
    const prePieces = {}, samePieces = {}, bs = current.boardSize, variant = (current.movable && current.movable.variant) || (current.premovable && current.premovable.variant);
    let curP, preP, i, prevGhosts = 0;
    for (i in prevPieces) {
        prePieces[i] = makePiece(i, bs, prevPieces[i]);
        if (prevPieces[i].role === 'ghostman' || prevPieces[i].role === 'ghostking')
            prevGhosts++;
    }
    for (const key of util.allKeys) {
        curP = current.pieces[key];
        preP = prePieces[key];
        if (curP) {
            if (preP) {
                if (!util.samePiece(curP, preP.piece)) {
                    if (preP.piece.color === 'white')
                        missingsW.push(preP);
                    else
                        missingsB.push(preP);
                    if (curP.color === 'white')
                        newsW.push(makePiece(key, bs, curP));
                    else
                        newsB.push(makePiece(key, bs, curP));
                }
            }
            else {
                if (curP.color === 'white')
                    newsW.push(makePiece(key, bs, curP));
                else
                    newsB.push(makePiece(key, bs, curP));
            }
        }
        else if (preP) {
            if (preP.piece.color === 'white')
                missingsW.push(preP);
            else
                missingsB.push(preP);
        }
    }
    const plan = { anims: {}, captures: {}, tempRole: {} };
    let nextPlan = { anims: {}, captures: {}, tempRole: {} };
    if (newsW.length > 1 && missingsW.length > 0) {
        newsW = newsW.sort((p1, p2) => {
            return util.distanceSq(missingsW[0].pos, p1.pos) - util.distanceSq(missingsW[0].pos, p2.pos);
        });
    }
    if (newsB.length > 1 && missingsB.length > 0) {
        newsB = newsB.sort((p1, p2) => {
            return util.distanceSq(missingsB[0].pos, p1.pos) - util.distanceSq(missingsB[0].pos, p2.pos);
        });
    }
    //Never animate capture sequences with ghosts on board, fixes retriggered animation when startsquare is touched again later in the sequence
    const captAnim = !noCaptSequences && (prevGhosts === 0 || current.animateFrom) && current.lastMove && current.lastMove.length > 2;
    const animateFrom = current.animateFrom || 0;
    //Animate captures with same start/end square
    if (!fadeOnly && captAnim && current.lastMove && current.lastMove[animateFrom] === current.lastMove[current.lastMove.length - 1]) {
        const doubleKey = current.lastMove[animateFrom];
        curP = current.pieces[doubleKey];
        preP = prePieces[doubleKey];
        if (curP.color === 'white' && missingsB.length !== 0) {
            missingsW.push(preP);
            newsW.push(makePiece(doubleKey, bs, curP));
        }
        else if (curP.color === 'black' && missingsW.length !== 0) {
            missingsB.push(preP);
            newsB.push(makePiece(doubleKey, bs, curP));
        }
    }
    let missings = missingsW.concat(missingsB), news = newsW.concat(newsB);
    if (news.length && missings.length && !fadeOnly) {
        news.forEach(newP => {
            let maybePromote = false, filteredMissings = missings.filter(p => !samePieces[p.key] && newP.piece.color === p.piece.color && (newP.piece.role === p.piece.role ||
                (p.piece.role === 'man' && newP.piece.role === 'king' && isPromotablePos(newP.piece.color, newP.pos, bs)) ||
                (p.piece.role === 'king' && newP.piece.role === 'man' && isPromotablePos(p.piece.color, p.pos, bs))));
            if (!filteredMissings.length && variant === 'russian') {
                maybePromote = true;
                filteredMissings = missings.filter(p => !samePieces[p.key] && newP.piece.color === p.piece.color && ((p.piece.role === 'man' && newP.piece.role === 'king') ||
                    (p.piece.role === 'king' && newP.piece.role === 'man')));
            }
            preP = closer(newP, filteredMissings);
            if (preP) {
                samePieces[preP.key] = true;
                let tempRole = (preP.piece.role === 'man' && newP.piece.role === 'king' && (maybePromote || isPromotablePos(newP.piece.color, newP.pos, bs))) ? 'man' : undefined;
                if (captAnim && current.lastMove && current.lastMove[animateFrom] === preP.key && current.lastMove[current.lastMove.length - 1] === newP.key) {
                    let lastPos = util.key2pos(current.lastMove[animateFrom + 1], bs), newPos;
                    plan.anims[newP.key] = getVector(preP.pos, lastPos);
                    plan.nextPlan = nextPlan;
                    if (tempRole)
                        plan.tempRole[newP.key] = tempRole;
                    const captKeys = new Array();
                    let captKey = board_1.calcCaptKey(prevPieces, bs, preP.pos[0], preP.pos[1], lastPos[0], lastPos[1]);
                    if (captKey) {
                        captKeys.push(captKey);
                        prevPieces[captKey] = ghostPiece(prevPieces[captKey]);
                    }
                    plan.captures = {};
                    missings.forEach(p => {
                        if (p.piece.color !== newP.piece.color) {
                            if (captKeys.indexOf(p.key) !== -1)
                                plan.captures[p.key] = ghostPiece(p.piece);
                            else
                                plan.captures[p.key] = p.piece;
                        }
                    });
                    let newPlan = { anims: {}, captures: {}, tempRole: {} };
                    for (i = animateFrom + 2; i < current.lastMove.length; i++) {
                        newPos = util.key2pos(current.lastMove[i], bs);
                        nextPlan.anims[newP.key] = getVector(lastPos, newPos);
                        nextPlan.anims[newP.key][2] = lastPos[0] - newP.pos[0];
                        nextPlan.anims[newP.key][3] = lastPos[1] - newP.pos[1];
                        nextPlan.nextPlan = newPlan;
                        if (tempRole) {
                            if (variant === 'russian' && isPromotablePos(newP.piece.color, lastPos, bs)) {
                                tempRole = undefined;
                            }
                            else {
                                nextPlan.tempRole[newP.key] = tempRole;
                            }
                        }
                        captKey = board_1.calcCaptKey(prevPieces, bs, lastPos[0], lastPos[1], newPos[0], newPos[1]);
                        if (captKey) {
                            captKeys.push(captKey);
                            prevPieces[captKey] = ghostPiece(prevPieces[captKey]);
                        }
                        nextPlan.captures = {};
                        missings.forEach(p => {
                            if (p.piece.color !== newP.piece.color) {
                                if (captKeys.indexOf(p.key) !== -1)
                                    nextPlan.captures[p.key] = ghostPiece(p.piece);
                                else
                                    nextPlan.captures[p.key] = p.piece;
                            }
                        });
                        lastPos = newPos;
                        nextPlan = newPlan;
                        newPlan = { anims: {}, captures: {}, tempRole: {} };
                    }
                }
                else {
                    plan.anims[newP.key] = getVector(preP.pos, newP.pos);
                    if (tempRole)
                        plan.tempRole[newP.key] = tempRole;
                }
            }
        });
    }
    return plan;
}
function getVector(preP, newP) {
    if (preP[1] % 2 === 0 && newP[1] % 2 === 0)
        return [preP[0] - newP[0], preP[1] - newP[1], 0, 0, -0.5];
    else if (preP[1] % 2 !== 0 && newP[1] % 2 === 0)
        return [preP[0] - newP[0] + 0.5, preP[1] - newP[1], 0, 0, -0.5];
    else if (preP[1] % 2 === 0 && newP[1] % 2 !== 0)
        return [preP[0] - newP[0] - 0.5, preP[1] - newP[1], 0, 0, 0];
    else
        return [preP[0] - newP[0], preP[1] - newP[1], 0, 0, 0];
}
function step(state, now) {
    let cur = state.animation.current;
    if (cur === undefined) { // animation was canceled :(
        if (!state.dom.destroyed)
            state.dom.redrawNow();
        return;
    }
    let rest = 1 - (now - cur.start) * cur.frequency;
    if (rest <= 0) {
        if (cur.plan.nextPlan && !util.isObjectEmpty(cur.plan.nextPlan.anims)) {
            state.animation.current = {
                start: performance.now(),
                frequency: 2.2 / state.animation.duration,
                plan: cur.plan.nextPlan,
                lastMove: state.lastMove
            };
            cur = state.animation.current;
            rest = 1 - (performance.now() - cur.start) * cur.frequency;
        }
        else
            state.animation.current = undefined;
    }
    if (state.animation.current !== undefined) {
        if (rest > 0.999)
            rest = 0.999;
        const ease = easing(rest);
        for (let i in cur.plan.anims) {
            const cfg = cur.plan.anims[i];
            cfg[2] = cfg[0] * ease;
            cfg[3] = cfg[1] * ease;
        }
        state.dom.redrawNow(true); // optimisation: don't render SVG changes during animations
        requestAnimationFrame((now = performance.now()) => step(state, now));
    }
    else
        state.dom.redrawNow();
}
function animate(mutation, state, fadeOnly = false, noCaptSequences = false) {
    // clone state before mutating it
    const prevPieces = Object.assign({}, state.pieces);
    const result = mutation(state);
    const plan = computePlan(prevPieces, state, fadeOnly, noCaptSequences);
    if (!util.isObjectEmpty(plan.anims)) {
        const alreadyRunning = state.animation.current && state.animation.current.start;
        state.animation.current = {
            start: performance.now(),
            frequency: ((plan.nextPlan && !util.isObjectEmpty(plan.nextPlan.anims)) ? 2.2 : 1) / state.animation.duration,
            plan: plan,
            lastMove: state.lastMove
        };
        if (!alreadyRunning)
            step(state, performance.now());
    }
    else {
        if (state.animation.current && !sameArray(state.animation.current.lastMove, state.lastMove)) {
            state.animation.current = undefined;
        }
        // don't animate, just render right away
        state.dom.redraw();
    }
    return result;
}
function sameArray(ar1, ar2) {
    if (!ar1 && !ar2)
        return true;
    if (!ar1 || !ar2 || ar1.length !== ar2.length)
        return false;
    for (let i = 0; i < ar1.length; i++) {
        if (ar1[i] !== ar2[i])
            return false;
    }
    return true;
}
// https://gist.github.com/gre/1650294
function easing(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}
