"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setKingMoves = exports.configure = void 0;
const board_1 = require("./board");
const fen_1 = require("./fen");
function configure(state, config) {
    // don't merge destinations. Just override.
    if (config.movable && config.movable.dests)
        state.movable.dests = undefined;
    if (config.movable && config.movable.captureUci)
        state.movable.captureUci = undefined;
    merge(state, config);
    if (config.fen) {
        // if a fen was provided, replace the pieces
        state.pieces = fen_1.read(config.fen, board_1.boardFields(state));
        state.drawable.shapes = [];
        // show kingmoves for frisian variants
        if (state.highlight && state.highlight.kingMoves) {
            const kingMoves = fen_1.readKingMoves(config.fen);
            if (kingMoves)
                doSetKingMoves(state, kingMoves);
        }
    }
    // apply config values that could be undefined yet meaningful
    if (config.hasOwnProperty('lastMove') && !config.lastMove) {
        state.lastMove = undefined;
        state.animateFrom = undefined;
    }
    // in case of ZH drop last move, there's a single square.
    // if the previous last move had two squares,
    // the merge algorithm will incorrectly keep the second square.
    else if (config.lastMove) {
        state.lastMove = config.lastMove;
        state.animateFrom = undefined;
    }
    if (config.captureLength !== undefined)
        state.movable.captLen = config.captureLength;
    // fix move/premove dests
    if (state.selected)
        board_1.setSelected(state, state.selected);
    // no need for such short animations
    if (!state.animation.duration || state.animation.duration < 100)
        state.animation.enabled = false;
}
exports.configure = configure;
;
function setKingMoves(state, kingMoves) {
    const fields = board_1.boardFields(state);
    for (let f = 1; f <= fields; f++) {
        const key = (f < 10 ? '0' + f.toString() : f.toString()), piece = state.pieces[key];
        if (piece && piece.kingMoves)
            piece.kingMoves = undefined;
    }
    doSetKingMoves(state, kingMoves);
}
exports.setKingMoves = setKingMoves;
function doSetKingMoves(state, kingMoves) {
    if (kingMoves.white.count > 0 && kingMoves.white.key) {
        const piece = state.pieces[kingMoves.white.key];
        if (piece && piece.role === 'king' && piece.color === 'white')
            piece.kingMoves = kingMoves.white.count;
    }
    if (kingMoves.black.count > 0 && kingMoves.black.key) {
        const piece = state.pieces[kingMoves.black.key];
        if (piece && piece.role === 'king' && piece.color === 'black')
            piece.kingMoves = kingMoves.black.count;
    }
}
function merge(base, extend) {
    for (let key in extend) {
        if (isObject(base[key]) && isObject(extend[key]))
            merge(base[key], extend[key]);
        else
            base[key] = extend[key];
    }
}
function isObject(o) {
    return typeof o === 'object';
}
