"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.start = void 0;
const board = require("./board");
const fen_1 = require("./fen");
const config_1 = require("./config");
const anim_1 = require("./anim");
const drag_1 = require("./drag");
const explosion_1 = require("./explosion");
// see API types and documentations in dts/api.d.ts
function start(state, redrawAll) {
    function toggleOrientation() {
        board.toggleOrientation(state);
        redrawAll();
    }
    ;
    return {
        set(config, noCaptSequences = false) {
            if (config.orientation && config.orientation !== state.orientation)
                toggleOrientation();
            if (config.fen) {
                anim_1.anim(state => config_1.configure(state, config), state, false, noCaptSequences);
                if (state.selected && !state.pieces[state.selected])
                    state.selected = undefined;
            }
            else
                anim_1.render(state => config_1.configure(state, config), state);
        },
        state,
        getFen: (algebraic) => fen_1.write(state.pieces, board.boardFields(state), algebraic),
        toggleOrientation,
        setPieces(pieces) {
            anim_1.anim(state => board.setPieces(state, pieces), state);
        },
        selectSquare(key, force) {
            if (key)
                anim_1.anim(state => board.selectSquare(state, key, force), state);
            else if (state.selected) {
                board.unselect(state);
                state.dom.redraw();
            }
        },
        move(orig, dest, finishCapture) {
            anim_1.anim(state => board.baseMove(state, orig, dest, finishCapture), state);
        },
        newPiece(piece, key) {
            anim_1.anim(state => board.baseNewPiece(state, piece, key), state);
        },
        setKingMoves(kingMoves) {
            config_1.setKingMoves(state, kingMoves);
        },
        playPremove() {
            if (state.premovable.current) {
                const dest = state.premovable.current ? state.premovable.current[1] : '00';
                if (anim_1.anim(board.playPremove, state)) {
                    //If we can continue capturing keep the piece selected to enable quickly clicking all target squares one after the other
                    if (state.movable.captLen && state.movable.captLen > 1)
                        board.setSelected(state, dest);
                    return true;
                }
                // if the premove couldn't be played, redraw to clear it up
                state.dom.redraw();
            }
            return false;
        },
        playPredrop(validate) {
            if (state.predroppable.current) {
                const result = board.playPredrop(state, validate);
                state.dom.redraw();
                return result;
            }
            return false;
        },
        cancelPremove() {
            anim_1.render(board.unsetPremove, state);
        },
        cancelPredrop() {
            anim_1.render(board.unsetPredrop, state);
        },
        cancelMove() {
            anim_1.render(state => { board.cancelMove(state); drag_1.cancel(state); }, state);
        },
        stop() {
            anim_1.render(state => { board.stop(state); drag_1.cancel(state); }, state);
        },
        explode(keys) {
            explosion_1.default(state, keys);
        },
        setAutoShapes(shapes) {
            anim_1.render(state => state.drawable.autoShapes = shapes, state);
        },
        setShapes(shapes) {
            anim_1.render(state => state.drawable.shapes = shapes, state);
        },
        getKeyAtDomPos(pos) {
            return board.getKeyAtDomPos(pos, state.boardSize, board.whitePov(state), state.dom.bounds());
        },
        redrawAll,
        dragNewPiece(piece, event, force) {
            drag_1.dragNewPiece(state, piece, event, force);
        },
        destroy() {
            board.stop(state);
            state.dom.unbind && state.dom.unbind();
            state.dom.destroyed = true;
        }
    };
}
exports.start = start;
