"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readKingMoves = exports.countGhosts = exports.toggleCoordinates = exports.write = exports.read = exports.initial = void 0;
const util_1 = require("./util");
exports.initial = 'W31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50:B1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20';
function read(fen, fields) {
    const pieces = {};
    if (!fen)
        return pieces;
    if (fen === 'start')
        fen = exports.initial;
    for (let fenPart of fen.split(':')) {
        if (fenPart.length <= 1)
            continue;
        let first = fenPart.slice(0, 1), clr;
        if (first === 'W')
            clr = 'white';
        else if (first === 'B')
            clr = 'black';
        else
            continue;
        const fenPieces = fenPart.slice(1).split(',');
        for (let fenPiece of fenPieces) {
            if (!fenPiece)
                continue;
            let fieldStr, role;
            switch (fenPiece.slice(0, 1)) {
                case 'K':
                    role = 'king';
                    fieldStr = fenPiece.slice(1);
                    break;
                case 'G':
                    role = 'ghostman';
                    fieldStr = fenPiece.slice(1);
                    break;
                case 'P':
                    role = 'ghostking';
                    fieldStr = fenPiece.slice(1);
                    break;
                default:
                    role = 'man';
                    fieldStr = fenPiece;
                    break;
            }
            if (fieldStr.length === 1)
                fieldStr = '0' + fieldStr;
            let fieldNumber = parseInt(fieldStr);
            if (!fieldNumber) {
                fieldNumber = util_1.algebraicKeys.indexOf(fieldStr) + 1;
            }
            if (fieldNumber && (!fields || fieldNumber <= fields)) {
                pieces[fieldStr] = {
                    color: clr,
                    role
                };
            }
        }
    }
    return pieces;
}
exports.read = read;
function write(pieces, fields, algebraic) {
    const max = fields || 50;
    let fenW = 'W', fenB = 'B';
    for (let f = 1; f <= max; f++) {
        const key = util_1.allKeys[f - 1], piece = pieces[key];
        if (!piece)
            continue;
        if (piece.color === 'white') {
            if (fenW.length > 1)
                fenW += ',';
            if (piece.role === 'king')
                fenW += 'K';
            else if (piece.role === 'ghostman')
                fenW += 'G';
            else if (piece.role === 'ghostking')
                fenW += 'P';
            if (algebraic)
                fenW += util_1.algebraicKeys[f - 1];
            else
                fenW += f.toString();
        }
        else {
            if (fenB.length > 1)
                fenB += ',';
            if (piece.role === 'king')
                fenB += 'K';
            else if (piece.role === 'ghostman')
                fenB += 'G';
            else if (piece.role === 'ghostking')
                fenB += 'P';
            if (algebraic)
                fenB += util_1.algebraicKeys[f - 1];
            else
                fenB += f.toString();
        }
    }
    return fenW + ':' + fenB;
}
exports.write = write;
function toggleCoordinates(fen, algebraic, fields) {
    if (!fen)
        return fen;
    if (fen === 'start')
        fen = exports.initial;
    const extraParts = [];
    let prefix = '', fenW = 'W', fenB = 'B';
    for (let fenPart of fen.split(':')) {
        let first = fenPart.slice(0, 1), clr;
        if (first === 'W')
            clr = true;
        else if (first === 'B')
            clr = false;
        else {
            extraParts.push(fenPart);
            continue;
        }
        if (fenPart.length === 1) {
            if (!prefix && !extraParts.length)
                prefix = first;
            continue;
        }
        const fenPieces = fenPart.slice(1).split(',');
        for (let fenPiece of fenPieces) {
            if (!fenPiece)
                continue;
            let fieldStr, role = fenPiece.slice(0, 1);
            switch (role) {
                case 'K':
                case 'G':
                case 'P':
                    fieldStr = fenPiece.slice(1);
                    break;
                default:
                    fieldStr = fenPiece;
                    role = '';
                    break;
            }
            if (algebraic) {
                const fieldNumber = parseInt(fieldStr);
                if (fieldNumber && (!fields || fieldNumber <= fields)) {
                    if (clr) {
                        if (fenW.length > 1)
                            fenW += ',';
                        fenW += role + util_1.algebraicKeys[fieldNumber - 1];
                    }
                    else {
                        if (fenB.length > 1)
                            fenB += ',';
                        fenB += role + util_1.algebraicKeys[fieldNumber - 1];
                    }
                }
                else if (!fieldNumber && util_1.algebraicKeys.includes(fieldStr)) {
                    return fen; // assume the FEN is already algebraic
                }
            }
            else {
                const coordIndex = util_1.algebraicKeys.indexOf(fieldStr);
                if (coordIndex !== -1 && (!fields || coordIndex < fields)) {
                    if (clr) {
                        if (fenW.length > 1)
                            fenW += ',';
                        fenW += role + (coordIndex + 1).toString();
                    }
                    else {
                        if (fenB.length > 1)
                            fenB += ',';
                        fenB += role + (coordIndex + 1).toString();
                    }
                }
                else if (coordIndex === -1 && parseInt(fieldStr)) {
                    return fen; // assume the FEN is already fieldnumbers
                }
            }
        }
    }
    const partsOut = prefix ? [prefix, fenW, fenB] : [fenW, fenB];
    return partsOut.concat(extraParts).join(':');
}
exports.toggleCoordinates = toggleCoordinates;
function countGhosts(fen) {
    if (!fen)
        return 0;
    if (fen === 'start')
        fen = exports.initial;
    let ghosts = 0;
    for (let fenPart of fen.split(':')) {
        if (fenPart.length <= 1)
            continue;
        let first = fenPart.slice(0, 1);
        if (first === 'W' || first === 'B') {
            const fenPieces = fenPart.slice(1).split(',');
            for (let fenPiece of fenPieces) {
                first = fenPiece.slice(0, 1);
                if (first === 'G' || first === 'P')
                    ghosts++;
            }
        }
    }
    return ghosts;
}
exports.countGhosts = countGhosts;
function readKingMoves(fen) {
    if (!fen)
        return undefined;
    if (fen === 'start')
        fen = exports.initial;
    const fenParts = fen.split(':'), kingMoves = fenParts.length ? fenParts[fenParts.length - 1] : '';
    if (kingMoves.indexOf('+') !== 0)
        return undefined;
    const playerMoves = kingMoves.split('+').filter(function (e) { return e.length != 0; });
    if (playerMoves.length !== 2)
        return undefined;
    const whiteMoves = parseInt(playerMoves[1].slice(0, 1)), blackMoves = parseInt(playerMoves[0].slice(0, 1)), result = {
        white: { count: whiteMoves },
        black: { count: blackMoves }
    };
    if (whiteMoves > 0)
        result.white.key = playerMoves[1].slice(1);
    if (blackMoves > 0)
        result.black.key = playerMoves[0].slice(1);
    return result;
}
exports.readKingMoves = readKingMoves;
