"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const util_1 = require("./util");
const util_2 = require("./util");
const svg_1 = require("./svg");
const board_1 = require("./board");
function wrap(element, s, relative) {
    // .cg-wrap (element passed to Chessground)
    //   cg-helper (10.0%)
    //     cg-container (1000%)
    //       cg-board
    //       svg
    //       coords.ranks
    //       coords.files
    //       piece.ghost
    element.innerHTML = '';
    // ensure the cg-wrap class is set
    // so bounds calculation can use the CSS width/height values
    // add that class yourself to the element before calling draughtsground
    // for a slight performance improvement! (avoids recomputing style)
    element.classList.add('cg-wrap');
    util_1.colors.forEach(c => element.classList.toggle('orientation-' + c, s.orientation === c));
    element.classList.toggle('manipulable', !s.viewOnly);
    const helper = util_1.createEl('cg-helper');
    element.appendChild(helper);
    const container = util_1.createEl('cg-container');
    helper.appendChild(container);
    const board = util_1.createEl('cg-board');
    container.appendChild(board);
    let svg;
    if (s.drawable.visible && !relative) {
        svg = svg_1.createElement('svg');
        svg.appendChild(svg_1.createElement('defs'));
        container.appendChild(svg);
    }
    if (s.coordinates) {
        if (s.coordinates === 2) {
            if (s.coordSystem === 1) {
                container.appendChild(renderCoords(util_2.ranksRev, 'ranks is64' + (s.orientation === 'black' ? ' black' : '')));
                container.appendChild(renderCoords(util_2.files, 'files is64' + (s.orientation === 'black' ? ' black' : '')));
            }
            else if (s.orientation === 'black') {
                const filesBlack = [], ranksBlack = [], rankBase = s.boardSize[0] / 2, fileSteps = s.boardSize[1] / 2;
                for (let i = 1; i <= rankBase; i++)
                    filesBlack.push(i);
                for (let i = 0; i < fileSteps; i++)
                    ranksBlack.push(rankBase + s.boardSize[0] * i + 1);
                container.appendChild(renderCoords(ranksBlack, 'ranks is100 black'));
                container.appendChild(renderCoords(filesBlack, 'files is100 black'));
            }
            else {
                const files = [], ranks = [], rankBase = s.boardSize[0] / 2, fields = s.boardSize[0] * s.boardSize[1] / 2, fileSteps = s.boardSize[1] / 2;
                for (let i = fields - rankBase + 1; i <= fields; i++)
                    files.push(i);
                for (let i = 0; i < fileSteps; i++)
                    ranks.push(rankBase + s.boardSize[0] * i);
                container.appendChild(renderCoords(ranks, 'ranks is100'));
                container.appendChild(renderCoords(files, 'files is100'));
            }
        }
        else if (!relative && s.coordinates === 1) {
            renderFieldnumbers(container, s, board.getBoundingClientRect());
        }
    }
    let ghost;
    if (s.draggable.showGhost && !relative) {
        ghost = util_1.createEl('piece', 'ghost');
        util_1.translateAway(ghost);
        container.appendChild(ghost);
    }
    return {
        board,
        container,
        ghost,
        svg
    };
}
exports.default = wrap;
function renderFieldnumbers(element, s, bounds) {
    const asWhite = s.orientation !== 'black', count = board_1.boardFields(s);
    for (let f = 1; f <= count; f++) {
        const field = util_1.createEl('fieldnumber', 'black'), san = f.toString();
        field.textContent = s.coordSystem === 1 ? util_1.san2alg[san] : san;
        const coords = util_1.posToTranslateAbs(bounds, s.boardSize)(util_1.key2pos(util_1.allKeys[f - 1], s.boardSize), asWhite, 0);
        util_1.translateAbs(field, [coords['0'], coords['1']]);
        element.appendChild(field);
    }
}
function renderCoords(elems, className) {
    const el = util_1.createEl('coords', className);
    let f;
    for (let i in elems) {
        f = util_1.createEl('coord');
        f.textContent = elems[i];
        el.appendChild(f);
    }
    return el;
}
