"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.whitePov = exports.boardFields = exports.unusedFieldAtDomPos = exports.getKeyAtDomPos = exports.stop = exports.cancelMove = exports.playPredrop = exports.playPremove = exports.isDraggable = exports.canMove = exports.unselect = exports.setSelected = exports.selectSquare = exports.dropNewPiece = exports.userMove = exports.baseNewPiece = exports.baseMove = exports.calcCaptKey = exports.unsetPredrop = exports.unsetPremove = exports.setPieces = exports.reset = exports.toggleOrientation = exports.callUserFunction = void 0;
const util_1 = require("./util");
const premove_1 = require("./premove");
const draughtsUtil = require("draughts");
function callUserFunction(f, ...args) {
    if (f)
        setTimeout(() => f(...args), 1);
}
exports.callUserFunction = callUserFunction;
function toggleOrientation(state) {
    state.orientation = util_1.opposite(state.orientation);
    state.animation.current =
        state.draggable.current =
            state.selected = undefined;
}
exports.toggleOrientation = toggleOrientation;
function reset(state) {
    state.lastMove = undefined;
    state.animateFrom = undefined;
    unselect(state);
    unsetPremove(state);
    unsetPredrop(state);
}
exports.reset = reset;
function setPieces(state, pieces) {
    for (let key in pieces) {
        const piece = pieces[key];
        if (piece)
            state.pieces[key] = piece;
        else
            delete state.pieces[key];
    }
}
exports.setPieces = setPieces;
function setPremove(state, orig, dest, meta) {
    unsetPredrop(state);
    state.premovable.current = [orig, dest];
    callUserFunction(state.premovable.events.set, orig, dest, meta);
}
function unsetPremove(state) {
    if (state.premovable.current) {
        state.premovable.current = undefined;
        callUserFunction(state.premovable.events.unset);
    }
}
exports.unsetPremove = unsetPremove;
function setPredrop(state, role, key) {
    unsetPremove(state);
    state.predroppable.current = { role, key };
    callUserFunction(state.predroppable.events.set, role, key);
}
function unsetPredrop(state) {
    const pd = state.predroppable;
    if (pd.current) {
        pd.current = undefined;
        callUserFunction(pd.events.unset);
    }
}
exports.unsetPredrop = unsetPredrop;
function calcCaptKey(pieces, boardSize, startX, startY, destX, destY) {
    const xDiff = destX - startX, yDiff = destY - startY;
    //Frisian captures always satisfy condition: (x = 0, y >= +-2) or (x = +-1, y = 0)
    //In normal captures these combination is impossible: x = 0 means y = 1, while y = 0 is impossible
    const yStep = yDiff === 0 ? 0 : (yDiff > 0 ? ((xDiff === 0 && Math.abs(yDiff) >= 2) ? 2 : 1) : ((xDiff === 0 && Math.abs(yDiff) >= 2) ? -2 : -1));
    const xStep = xDiff === 0 ? 0 : (yDiff === 0 ? (xDiff > 0 ? 1 : -1) : (startY % 2 == 0 ? (xDiff < 0 ? -1 : 0) : (xDiff > 0 ? 1 : 0)));
    if (xStep === 0 && yStep === 0)
        return undefined;
    const captPos = [startX + xStep, startY + yStep];
    if (captPos === undefined)
        return undefined;
    const captKey = util_1.pos2key(captPos, boardSize);
    const piece = pieces[captKey];
    if (piece !== undefined && piece.role !== 'ghostman' && piece.role !== 'ghostking')
        return captKey;
    else
        return calcCaptKey(pieces, boardSize, startX + xStep, startY + yStep, destX, destY);
}
exports.calcCaptKey = calcCaptKey;
function inArray(arr, predicate) {
    for (let s of arr) {
        if (predicate(s))
            return s;
    }
    return undefined;
}
function baseMove(state, orig, dest, finishCapture) {
    if (orig === dest || !state.pieces[orig]) {
        // remove any remaining ghost pieces if capture sequence is done
        if (finishCapture) {
            for (let i = 0; i < util_1.allKeys.length; i++) {
                const k = util_1.allKeys[i], pc = state.pieces[k];
                if (pc && (pc.role === 'ghostking' || pc.role === 'ghostman'))
                    delete state.pieces[k];
            }
            if (dest == state.selected)
                unselect(state);
        }
        return false;
    }
    const isCapture = (state.movable.captLen && state.movable.captLen > 0), bs = state.boardSize;
    const captureUci = isCapture && state.movable.captureUci && inArray(state.movable.captureUci, (uci) => uci.slice(0, 2) === orig && uci.slice(-2) === dest);
    const origPos = util_1.key2pos(orig, bs), destPos = captureUci ? util_1.key2pos(captureUci.slice(2, 4), bs) : util_1.key2pos(dest, bs);
    const captKey = isCapture ? calcCaptKey(state.pieces, bs, origPos[0], origPos[1], destPos[0], destPos[1]) : undefined;
    const captPiece = (isCapture && captKey) ? state.pieces[captKey] : undefined;
    const origPiece = state.pieces[orig];
    if (dest == state.selected)
        unselect(state);
    callUserFunction(state.events.move, orig, dest, captPiece);
    const captured = captureUci ? (captureUci.length - 2) / 2 : 1, finalDest = captureUci ? util_1.key2pos(captureUci.slice(captureUci.length - 2), bs) : destPos, variant = (state.movable && state.movable.variant) || (state.premovable && state.premovable.variant), promotable = (variant === 'russian' || !state.movable.captLen || state.movable.captLen <= captured) &&
        origPiece.role === 'man' && ((origPiece.color === 'white' && finalDest[1] === 1) ||
        (origPiece.color === 'black' && finalDest[1] === state.boardSize[1]));
    const destPiece = (!state.movable.free && promotable) ? {
        role: 'king',
        color: origPiece.color
    } : state.pieces[orig];
    delete state.pieces[orig];
    if (captureUci && captKey) {
        delete state.pieces[captKey];
        const maybePromote = destPiece.role === 'man' && variant === 'russian', promoteAt = origPiece.color === 'white' ? 1 : state.boardSize[1];
        let doPromote = false;
        for (let s = 2; s + 4 <= captureUci.length; s += 2) {
            const nextOrig = util_1.key2pos(captureUci.slice(s, s + 2), bs), nextDest = util_1.key2pos(captureUci.slice(s + 2, s + 4), bs), nextCapt = calcCaptKey(state.pieces, bs, nextOrig[0], nextOrig[1], nextDest[0], nextDest[1]);
            if (nextCapt) {
                delete state.pieces[nextCapt];
            }
            if (maybePromote && nextOrig[1] === promoteAt) {
                doPromote = true;
            }
        }
        if (doPromote) {
            destPiece.role = 'king';
        }
        state.pieces[dest] = destPiece;
    }
    else {
        state.pieces[dest] = destPiece;
        if (captKey) {
            const captColor = state.pieces[captKey].color;
            const captRole = state.pieces[captKey].role;
            delete state.pieces[captKey];
            //Show a ghostpiece when we capture more than once
            if (!finishCapture && state.movable.captLen !== undefined && state.movable.captLen > 1) {
                if (captRole === 'man') {
                    state.pieces[captKey] = {
                        role: 'ghostman',
                        color: captColor
                    };
                }
                else if (captRole === 'king') {
                    state.pieces[captKey] = {
                        role: 'ghostking',
                        color: captColor
                    };
                }
            }
            else {
                //Remove any remaing ghost pieces if capture sequence is done
                for (let i = 0; i < util_1.allKeys.length; i++) {
                    const k = util_1.allKeys[i], pc = state.pieces[k];
                    if (pc && (pc.role === 'ghostking' || pc.role === 'ghostman'))
                        delete state.pieces[k];
                }
            }
        }
    }
    if (state.lastMove && state.lastMove.length && isCapture && state.lastMove[state.lastMove.length - 1] === orig) {
        state.animateFrom = state.lastMove.length - 1;
        if (captureUci)
            state.lastMove = state.lastMove.concat(draughtsUtil.decomposeUci(captureUci.slice(2)));
        else
            state.lastMove.push(dest);
    }
    else {
        state.animateFrom = 0;
        if (captureUci)
            state.lastMove = draughtsUtil.decomposeUci(captureUci);
        else
            state.lastMove = [orig, dest];
    }
    callUserFunction(state.events.change);
    return captPiece || true;
}
exports.baseMove = baseMove;
function baseNewPiece(state, piece, key, force) {
    if (state.pieces[key]) {
        if (force)
            delete state.pieces[key];
        else
            return false;
    }
    callUserFunction(state.events.dropNewPiece, piece, key);
    state.pieces[key] = piece;
    state.lastMove = [key];
    callUserFunction(state.events.change);
    state.movable.dests = undefined;
    state.turnColor = util_1.opposite(state.turnColor);
    return true;
}
exports.baseNewPiece = baseNewPiece;
function baseUserMove(state, orig, dest) {
    const result = baseMove(state, orig, dest);
    if (result) {
        state.movable.dests = undefined;
        if (!state.movable.captLen || state.movable.captLen <= 1)
            state.turnColor = util_1.opposite(state.turnColor);
        state.animation.current = undefined;
    }
    return result;
}
/**
 * User has finished a move, either by drag or click src->dest
 */
function userMove(state, orig, dest) {
    if (canMove(state, orig, dest)) {
        const result = baseUserMove(state, orig, dest);
        if (result) {
            const holdTime = state.hold.stop();
            unselect(state);
            const metadata = {
                premove: false,
                ctrlKey: state.stats.ctrlKey,
                holdTime
            };
            if (result !== true)
                metadata.captured = result;
            callUserFunction(state.movable.events.after, orig, dest, metadata);
            return true;
        }
    }
    else if (canPremove(state, orig, dest)) {
        setPremove(state, orig, dest, {
            ctrlKey: state.stats.ctrlKey
        });
        unselect(state);
        return true;
    }
    unselect(state);
    return false;
}
exports.userMove = userMove;
function dropNewPiece(state, orig, dest, force) {
    if (canDrop(state, orig, dest) || force) {
        const piece = state.pieces[orig];
        delete state.pieces[orig];
        baseNewPiece(state, piece, dest, force);
        callUserFunction(state.movable.events.afterNewPiece, piece.role, dest, {
            predrop: false
        });
    }
    else if (canPredrop(state, orig, dest)) {
        setPredrop(state, state.pieces[orig].role, dest);
    }
    else {
        unsetPremove(state);
        unsetPredrop(state);
    }
    delete state.pieces[orig];
    unselect(state);
}
exports.dropNewPiece = dropNewPiece;
function selectSquare(state, key, force) {
    callUserFunction(state.events.select, key);
    if (state.selected) {
        if (state.selected === key && !state.draggable.enabled) {
            unselect(state);
            state.hold.cancel();
            return;
        }
        else if ((state.selectable.enabled || force) && state.selected !== key) {
            if (userMove(state, state.selected, key)) {
                state.stats.dragged = false;
                const skipLastMove = state.animateFrom ? state.animateFrom + 1 : 1;
                if (state.movable.captLen !== undefined && state.movable.captLen > (state.lastMove ? state.lastMove.length - skipLastMove : 1)) {
                    // if we can continue capturing, keep the piece selected
                    setSelected(state, key);
                }
                return;
            }
        }
    }
    if (isMovable(state, key) || isPremovable(state, key)) {
        setSelected(state, key);
        state.hold.start();
    }
}
exports.selectSquare = selectSquare;
function setSelected(state, key) {
    state.selected = key;
    if (isPremovable(state, key)) {
        state.premovable.dests = premove_1.default(state.pieces, state.boardSize, key, state.premovable.variant);
    }
    else
        state.premovable.dests = undefined;
}
exports.setSelected = setSelected;
function unselect(state) {
    state.selected = undefined;
    state.premovable.dests = undefined;
    state.hold.cancel();
}
exports.unselect = unselect;
function isMovable(state, orig) {
    const piece = state.pieces[orig];
    return piece && (state.movable.color === 'both' || (state.movable.color === piece.color &&
        state.turnColor === piece.color));
}
function canMove(state, orig, dest) {
    return orig !== dest && isMovable(state, orig) && (state.movable.free || (!!state.movable.dests && util_1.containsX(state.movable.dests[orig], dest)));
}
exports.canMove = canMove;
function canDrop(state, orig, dest) {
    const piece = state.pieces[orig];
    return piece && dest && (orig === dest || !state.pieces[dest]) && (state.movable.color === 'both' || (state.movable.color === piece.color &&
        state.turnColor === piece.color));
}
function isPremovable(state, orig) {
    const piece = state.pieces[orig];
    return piece && state.premovable.enabled &&
        state.movable.color === piece.color &&
        state.turnColor !== piece.color;
}
function canPremove(state, orig, dest) {
    return orig !== dest &&
        isPremovable(state, orig) &&
        util_1.containsX(premove_1.default(state.pieces, state.boardSize, orig, state.premovable.variant), dest);
}
function canPredrop(state, orig, dest) {
    const piece = state.pieces[orig];
    return piece && dest &&
        (!state.pieces[dest] || state.pieces[dest].color !== state.movable.color) &&
        state.predroppable.enabled &&
        state.movable.color === piece.color &&
        state.turnColor !== piece.color;
}
function isDraggable(state, orig) {
    const piece = state.pieces[orig];
    return piece && state.draggable.enabled && (state.movable.color === 'both' || (state.movable.color === piece.color && (state.turnColor === piece.color || state.premovable.enabled)));
}
exports.isDraggable = isDraggable;
function playPremove(state) {
    const move = state.premovable.current;
    if (!move)
        return false;
    const orig = move[0], dest = move[1];
    let success = false;
    if (canMove(state, orig, dest)) {
        const result = baseUserMove(state, orig, dest);
        if (result) {
            const metadata = { premove: true };
            if (result !== true)
                metadata.captured = result;
            callUserFunction(state.movable.events.after, orig, dest, metadata);
            success = true;
        }
    }
    unsetPremove(state);
    return success;
}
exports.playPremove = playPremove;
function playPredrop(state, validate) {
    let drop = state.predroppable.current, success = false;
    if (!drop)
        return false;
    if (validate(drop)) {
        const piece = {
            role: drop.role,
            color: state.movable.color
        };
        if (baseNewPiece(state, piece, drop.key)) {
            callUserFunction(state.movable.events.afterNewPiece, drop.role, drop.key, {
                predrop: true
            });
            success = true;
        }
    }
    unsetPredrop(state);
    return success;
}
exports.playPredrop = playPredrop;
function cancelMove(state) {
    unsetPremove(state);
    unsetPredrop(state);
    unselect(state);
}
exports.cancelMove = cancelMove;
function stop(state) {
    state.movable.color =
        state.movable.dests =
            state.animation.current = undefined;
    cancelMove(state);
}
exports.stop = stop;
function getKeyAtDomPos(pos, boardSize, asWhite, bounds) {
    let row = Math.ceil(boardSize[1] * ((pos[1] - bounds.top) / bounds.height));
    if (!asWhite)
        row = (boardSize[1] + 1) - row;
    let col = Math.ceil(boardSize[0] * ((pos[0] - bounds.left) / bounds.width));
    if (!asWhite)
        col = (boardSize[0] + 1) - col;
    // on odd rows we skip fields 1,3,5 etc and on even rows 2,4,6 etc
    if (row % 2 !== 0) {
        if (col % 2 !== 0)
            return undefined;
        else
            col = col / 2;
    }
    else {
        if (col % 2 === 0)
            return undefined;
        else
            col = (col + 1) / 2;
    }
    return (col > 0 && col <= boardSize[0] / 2 && row > 0 && row <= boardSize[1]) ? util_1.pos2key([col, row], boardSize) : undefined;
}
exports.getKeyAtDomPos = getKeyAtDomPos;
function unusedFieldAtDomPos(pos, boardSize, asWhite, bounds) {
    let row = Math.ceil(boardSize[1] * ((pos[1] - bounds.top) / bounds.height));
    if (!asWhite)
        row = (boardSize[1] + 1) - row;
    let col = Math.ceil(boardSize[0] * ((pos[0] - bounds.left) / bounds.width));
    if (!asWhite)
        col = (boardSize[0] + 1) - col;
    if (row % 2 !== 0) {
        if (col % 2 !== 0)
            return true;
    }
    else {
        if (col % 2 === 0)
            return true;
    }
    return false;
}
exports.unusedFieldAtDomPos = unusedFieldAtDomPos;
function boardFields(s) {
    return s.boardSize[0] * s.boardSize[1] / 2;
}
exports.boardFields = boardFields;
function whitePov(s) {
    return s.orientation === 'white';
}
exports.whitePov = whitePov;
